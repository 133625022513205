/* make the customizations */
$theme-colors: (
    "primary": #05386B,
    "secondary": #F64C72
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn-grey {
  color: #05386B;
  background-color: #DEE2E6;
  border-radius: 5px;
}
.btn-grey:hover{
  background-color: #e7e7e7;
  color: #F64C72;
}
.btn-outline-grey {
  color: #05386B;
  border-color: #DEE2E6;
  border-width: 2.5px;
  border-radius: 5px;
}
.btn-outline-grey:hover {
  color: #05386B;
  background-color: #DEE2E6;
  // border-color: #DEE2E6;
}
.btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active, .show > .btn-outline-grey.dropdown-toggle {
  color: #05386B;
  background-color: #DEE2E6;
  border-color: #DEE2E6;
}

.nav-grey-pills .nav-link.active, .nav-grey-pills .show > .nav-link {
  color: #05386B;
  background-color: #DEE2E6;
}
.nav-grey-pills .nav-link {
  border-radius: 0.25rem;
}