/* html { 
  overflow-y: scroll; 
} */
/* .gm-style-iw > button {
  display: none;
} */
.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 40vmin;
  pointer-events: none;
}

.App-logo-loading {
  animation: App-logo-spin infinite 3s linear;
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* [data-amplify-theme='my-theme'] {
  --amplify-colors-brand-primary: pink;
} */

[data-amplify-authenticator] {
  background-color: #F1F1F1;
  height: 100vh;
  padding-left: 80px;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 26rem) {
  [data-amplify-authenticator] [data-amplify-container] {
    width: 26rem;
  }
}
